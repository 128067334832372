var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-6"},[_vm._m(0),(
      _vm.$store.state.auth.user &&
      (!_vm.documentRoles.includes(_vm.$store.state.auth.user.roles[0].name) &&
      _vm.canAccessMeetings) || _vm.$store.state.auth.user.roles[0].name === 'ADMIN'
    )?_c('div',[_c('AppointmentChart')],1):_vm._e(),(
      _vm.$store.state.auth.user &&
      (_vm.documentRoles.includes(_vm.$store.state.auth.user.roles[0].name) &&
      _vm.canAccessDocuments) || _vm.$store.state.auth.user.roles[0].name === 'ADMIN'
    )?_c('div',[_c('DocumentChartUi')],1):_vm._e(),(_vm.$store.state.auth.user && (_vm.canAccessProjects || _vm.$store.state.auth.user.roles[0].name === 'ADMIN'))?_c('div',[_c('ProjectChartUi')],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-justify ml-12"},[_c('h1',{staticClass:"font-bold text-sm sm:text-2xl"},[_vm._v("Welcome to the MINICAAF LOG")])])}]

export { render, staticRenderFns }