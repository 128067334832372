<template>
  <div class="sm:flex">
    <div
      class="chart shadow-md mt-8 mx-4 lg:ml-10 pt-5 pb-2.5 p px-7 custom-scroll"
    >
      <div class="lg:flex text-left">
        <h5 class="mb-4 lg:mb-0"><b>Meetings</b></h5>
        <div class="lg:ml-40 lg:flex">
          <div class="select">
            <select
              class="month-select border-black border mb-4 lg:mb-0 p-1.5 w-36 rounded-sm border-gray-300"
              @change="onSelectedMonthChange($event)"
            >
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </div>
          <div class="select">
            <select
              class="year-select border-black border p-1.5 mb-4 lg:mb-0 w-36 lg:ml-8 border-gray-300"
              @change="onSelectedYearChange($event)"
            >
              <option
                v-for="year in appointmentYears"
                :key="year.year"
                :value="year.year"
              >
                {{ year.year }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <apexchart
        width="550"
        type="line"
        :options="chartOptions"
        :series="annualChartData"
      ></apexchart>
    </div>
    <div
      class="progress-bars bg-white mt-8 ml-4"
      v-if="$store.state.auth.user && $store.state.auth.user.roles[0].name !== 'SECRETARY'"
    >
      <progress-bars />
    </div>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import ProgressBars from "./ProgressBars";
import Api from "@/services/Apis";
import { monthDays } from "@/utils/functions";
export default {
  name: "DashboardChart",
  components: {
    apexchart: VueApexCharts,
    ProgressBars,
  },
  data() {
    return {
      currentSelectedYear: 0,
      currentSelectedMonth: (new Date()).getMonth()+1,
      monthlyChartData: [],
      annualChartData: [],
      AUTH_USER_ROLE: "",
      appointmentYears: [],
      chartOptions: {
        colors: ["#9E8EF4"],
        noData: {
          text: "Loading...",
        },

        stroke: {
          width: 1,
          colors: ["#9E8EF4"],
        },
        markers: {
          size: 3,
          colors: ["#9E8EF4"],
        },

        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
            },
            title: {
              text: "Total Meetings",
            },
          },
        ],
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
        },

        tooltip: {
          enabled: true,
          // x: {
          //   formatter: function() {
          //     return new Date().toDateString();
          //   },
          // },
        },
        legend: {
          show: false,
        },
        chart: {
          toolbar: false,
          annotation: " ",
        },
        xaxis: {
          name: "Total Meetings",
          categories: monthDays(1, 31),
          title: {
            text: "Months",
          },
        },
      },
    };
  },
  methods: {
    async getAppointmentYears() {
      await Api.get("statistics/appointments/list-years")
        .then((resp) => {
          this.appointmentYears = resp.data;
          this.currentSelectedYear = this.appointmentYears[0].year;
          this.getMonthlyAppointments(
            this.currentSelectedYear,
            this.currentSelectedMonth
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getMonthlyAppointments(year, month) {
      await Api.get("statistics/appointments/" + year + "/" + month)
        .then((res) => {
          this.annualChartData = [];
          const chart = {
            label: "Total Meetings",
            name: "Total Meetings",
            data: res.data.totals,
          };
          this.annualChartData.push(chart);
          this.displayCurrent();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // async getMonthlyAppointments(month, year) {
    //   await Api.get("statistics/appointments/" + year + "/" + month)
    //     .then((res) => {
    //       this.series = [
    //         {
    //           label: "Total Appointments",
    //           name: "Total Appointments",
    //           data: [
    //             100,
    //             200,
    //             310,
    //             410,
    //             530,
    //             650,
    //             760,
    //             810,
    //             880,
    //             900,
    //             916,
    //             1000,
    //           ],
    //         },
    //       ];
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    onSelectedYearChange(e) {
      this.currentSelectedYear = e.target.value;
      this.getMonthlyAppointments(
        this.currentSelectedYear,
        this.currentSelectedMonth
      );
    },
    onSelectedMonthChange(e) {
      this.currentSelectedMonth = e.target.value;
      this.getMonthlyAppointments(
        this.currentSelectedYear,
        this.currentSelectedMonth
      );
    },

    checkCurrentDate(e) {
       if(e.value == this.currentSelectedMonth) {
         console.log('selected')
       }
    },

    displayCurrent() {
      let options = document.querySelectorAll('option');
      console.log(options)
      options[this.currentSelectedMonth-1].selected = true;
    }
  },
  created() {
    this.getAppointmentYears();
  },
};
</script>
<style scoped lang="scss">
.chart {
  background: white;
  height: 28rem;
}
.progress-bars {
  background: white;
  height: 28rem;
  width: 17.5rem;
}

@media (max-width: 700px) {
  .chart {
    height: auto;
    max-width: 100%;
    width: 91%;
    overflow-x: scroll !important;
  }
  .main {
    height: 75rem;
  }
}
@media (min-width: 710px) and (max-width: 1024px) {
  .main {
    display: block;
    height: 102rem;
  }
  .chart {
    max-width: 100%;
    width: 94%;
    height: auto;
  }
}
</style>
