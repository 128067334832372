<template>
  <div class="lg:mt-6" id="main">
    <h1 class="text-justify pt-4 pb-1 pl-8 font-bold text-xl text-primary">
      Total Documents
    </h1>
    <div class="flex">
      <h1 class="text-bigger text-justify pl-8 font-bold text-4xl">
        {{ documents.total }}
      </h1>
      <img
        src="/icons/Polygon.svg"
        alt=""
        v-if="documents.hasIncreased"
        class="ml-2 polygon"
      />
      <img src="/icons/Polygon.png" alt="" v-else class="ml-2 polygon" />
      <h3 class="text-secondary pt-3 ml-2">{{ documents.percentage !== 'NaN' ? documents.percentage.toFixed(2) + "%": "Documents not yet attached" }}</h3>
    </div>

    <h3 class="text-final text-sm text-center">
      {{ `${"Compared to last " + documents.previousYearTotal + " year"}` }}
    </h3>
    <div class="pt-4" v-for="(stat, index) in stats" :key="stat.status">
      <div class="pb-2">
        <ProgressBar
          :count="stat.total"
          :color="colors[index]"
          :title="stat.status"
          :total="documents.total"
        />
      </div>
      <!-- <div class="mt-2 pb-1">
        <progress-bar
          :count="40"
          color="#28A4E2"
          title="Approved"
          :total="50"
        />
      </div>
      <div class="mt-2 pb-1">
        <progress-bar :count="10" color="#FFBA69" title="On hold" :total="50" />
      </div>
      <div class="mt-2 pb-1">
        <progress-bar
          :count="70"
          color="#FF708B"
          title="Returned"
          :total="50"
        />
      </div> -->
      <!-- <div class="mt-2 pb-1.5">
  <progress-bar :completed="85" color="#00B929" title="Passed to the Cabinet"/>
      </div> -->
    </div>
  </div>
</template>
<script>
import ProgressBar from "./ProgressBar.vue";
import Api from "../services/Apis";

export default {
  name: "ProgressBarImplementation",
  components: {
    ProgressBar,
  },
  data() {
    return {
      documents: [],
      stats: [],
      colors: ["#8676FF", "#28A4E2", "#FFBA69", "#FF708B"],
    };
  },
  methods: {
    async getDocuments() {
      await Api.get("statistics/documents")
        .then((response) => {
          this.documents = response?.data;
          this.stats = response.data.statuses;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.getDocuments();
  },
};
</script>
<style scoped lang="scss">
#main {
  .polygon {
    width: 1rem;
    margin: 1.2rem 0 0 0.5rem;
    height: 1rem;
  }
}
</style>
