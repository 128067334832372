<template>
  <div class="sm:flex mt-8">
    <div
      class="chart shadow-md mx-4 lg:ml-10 pt-5 pb-2.5 p px-7 filled custom-scroll"
    >
      <div class="lg:flex text-left">
        <h5 class="mb-4 lg:mb-0"><b>Projects Statistics</b></h5>
        <div class="lg:ml-40 lg:flex items-end">
          <div class="select">
            <select
              class="year-select border-black border p-1.5 mb-4 lg:mb-0 w-36 lg:ml-36 border-gray-300"
              @change="onSelectedYearChange($event)"
            >
            <option
                v-for="year in documentYears"
                :key="year.year"
                :value="year.year"
              >
                {{ year.year }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <Apexchart
        width="550"
        type="line"
        :options="chartOptions"
        :series="monthlyChartData"
      ></Apexchart>
    </div>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import ProgressBars from "./ProgressBars.vue";
import Api from "../services/Apis";
export default {
  name: "ProjecyChart",
  components: {
    Apexchart: VueApexCharts,
    ProgressBars,
  },
  data() {
    return {
      currentSelectedYear: 0,
      currentSelectedMonth: 1,
      monthlyChartData: [],
      annualChartData: [],
      documentYears: [],
      AUTH_USER_ROLE: "",
      chartOptions: {
        stroke: {
          width: 1,
        },
        markers: {
          size: 3,
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
            },
            title: {
              text: "Total Projects",
            },
          },
        ],
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
        },

        tooltip: {
          enabled: true,
          x: {
            formatter: function() {
              return new Date().toDateString();
            },
          },
        },
        legend: {
          show: false,
        },
        chart: {
          toolbar: false,
          annotation: " ",
        },
        xaxis: {
          name: "Total Projects",
          // labels:"Appointments",
          categories: [
            "Jan",
            "Feb",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "Sept",
            "october",
            "November",
            "December",
          ],
          title: {
            text: "Months",
          },
        },
      },
    };
  },
  methods: {
    async getDocumentYears() {
      await Api.get("statistics/projects/list-years")
        .then((resp) => {
          this.documentYears = resp.data;
          this.currentSelectedYear = this.documentYears[0].year;
          this.getAnnualDocuments(this.currentSelectedYear);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getAnnualDocuments(year) {
      await Api.get(`statistics/projects/year/` + year)
        .then((res) => {
       
          this.monthlyChartData = [];
         
          for (const documentStat of res.data) {
                // let j = 0;
            const chart = {
              label: documentStat.cluster,
              name: documentStat.cluster,
              data: documentStat.totals,
            };
            this.monthlyChartData.push(chart);
            // j++;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    onSelectedYearChange(e) {
      this.currentSelectedYear = e.target.value;
      this.getAnnualDocuments(this.currentSelectedYear);
    },
  },
  mounted() {
    this.getDocumentYears();
  },
};
</script>
<style scoped lang="scss">
.chart {
  background: white;
  height: 28rem;
}
.filled {
  width: 65%;
}
.progress-bars {
  background: white;
  height: 28rem;
  width: 17rem;
}
@media (min-width: 200px) and (max-width: 640px) {
  .filled {
    width: 100%;
  }
}
@media (max-width: 700px) {
  .chart {
    height: auto;
    max-width: 100%;
    width: 91%;
    overflow-x: scroll !important;
  }
  .main {
    height: 75rem;
  }
}
@media (min-width: 700px) and (max-width: 1024px) {
  .main {
    display: block;
    height: 102rem;
  }
  .chart {
    height: auto;
    max-width: 100%;
    width: 94%;
  }
  .progress-bars{
    margin-left: 2.5rem;
  }
}
</style>
