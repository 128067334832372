<template>
  <div class="my-6">
    <div class="text-justify ml-12">
      <h1 class="font-bold text-sm sm:text-2xl">Welcome to the MINICAAF LOG</h1>
      <!-- <p class="text-sm">Below is your meeeting status</p> -->
    </div>
    <div
      v-if="
        $store.state.auth.user &&
        (!documentRoles.includes($store.state.auth.user.roles[0].name) &&
        canAccessMeetings) || $store.state.auth.user.roles[0].name === 'ADMIN'
      "
    >
      <AppointmentChart />
    </div>
    <div
      v-if="
        $store.state.auth.user &&
        (documentRoles.includes($store.state.auth.user.roles[0].name) &&
        canAccessDocuments) || $store.state.auth.user.roles[0].name === 'ADMIN'
      "
    >
      <DocumentChartUi />
    </div>
    <div v-if="$store.state.auth.user && (canAccessProjects || $store.state.auth.user.roles[0].name === 'ADMIN')">
      <ProjectChartUi />
    </div>
  </div>
</template>

<script>
import Api from "/src/services/Apis";
import AppointmentChart from "/src/components/Dashboardchart.vue";
import DocumentChartUi from "/src/components/DocumentChart.vue";
import ProjectChartUi from "/src/components/ProjectChart.vue";
export default {
  name: "Dashboard",
  components: {
    AppointmentChart,
    DocumentChartUi,
    ProjectChartUi,
  },

  data() {
    return {
      documentRoles: [
        "CLUSTER_USER",
        "CLUSTER_ADMIN",
        "MINISTRY",
        "GAZETTE_PROCESSOR",
      ],
      canAccessDocuments: false,
      canAccessMeetings: false,
      canAccessProjects: false,
    };
  },
  methods: {
    checkDocumentAccess() {
      Api.get("projects/role/user").then((response) => {
        if (response.data.data) {
          this.canAccessDocuments = response.data.data.canAccessDocuments;
          this.canAccessMeetings = response.data.data.canAccessMeetings;
          this.canAccessProjects = response.data.data.canAccessProjects;
        }
      });
    },
  },

  async created() {
    this.checkDocumentAccess();
  },
};
</script>
<style lang="scss" scoped>
.body {
  grid-template-columns: 1fr 19fr;
  .main {
    max-width: 100%;
    overflow-x: hidden;
  }
}
</style>
