<template>
  <div id="combined">
    <div class="flex justify-between">
      <h3 class="text-justify ml-4 text-primary pb-1.5 text-xs">
        {{ title }}
      </h3>
      <h3 class="mr-4 text-completed text-xs">{{ count }}</h3>
    </div>
    <div class="containerStyles">
      <div
        class="filterStyles"
        :style="
          'width:' + (count / total) * 100 + '%; background-color: ' + color
        "
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProgressBar",
  props: {
    count: {
      type: Number,
      required: true,
    },
    color: {
      // type: String ,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
#combined {
  .containerStyles {
    width: 16rem;
    background-color: #e0e0de;
    margin: 0 0 0 11px;
    height: 0.6rem;
    border-radius: 13px;
  }
  .filterStyles {
    height: 100%;
    width: 0%;
    border-radius: inherit;
    text-align: right;
  }
}
</style>
